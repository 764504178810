  <template>
    <div id="app">
      <!--        顶部-->
      <div style="width: 100%;height: 60px;background: #031F88;overflow:hidden;">
        <!--   log  -->
        <div style="width: 120px;height: 38px;margin: 10px 30px;">
          <img src="https://oss.baigongbao.com/2021/02/04/Cr3y7bZDaF.png" style="width: 120px;height: 38px;">
        </div>
        <div style="display: flex;justify-content: center;align-content: center;margin-top: -42px">
          <div style="margin: 5px;">
            <svg t="1691215958349" class="icon" viewBox="0 0 1135 1024" version="1.1" xmlns="http://www.w3.org/2000/svg"
                 p-id="2748" width="18" height="18">
              <path
                  d="M1079.730672 242.271723c-16.351297-52.324152-29.432335-104.648303-42.513373-156.972455C1027.40652 42.785895 1007.784963 10.0833 958.731071 0.272522H379.895143C350.462808 0.272522 324.300732 3.542781 294.868397 3.542781H203.301131c-52.324152 0-81.756487 22.891816-94.837525 75.215968-32.702595 104.648303-62.13493 212.566866-91.567265 317.21517-3.270259 13.081038-9.810778 19.621557-16.351298 29.432335v555.944112c9.810778 22.891816 26.162076 32.702595 45.783633 42.513373h1062.834331c16.351297-6.540519 22.891816-22.891816 26.162076-35.972854V422.135995c-29.432335-55.594411-35.972854-117.729341-55.594411-179.864272z m-994.158882 147.161677c22.891816-98.107784 42.513373-196.215569 65.405189-297.593613 6.540519-29.432335 19.621557-42.513373 52.324152-42.513373H929.298736c49.053892 0 55.594411 3.270259 65.405189 52.324152 22.891816 107.918563 49.053892 219.107385 75.215969 330.296207v9.810779h-997.429142c3.270259-19.621557 6.540519-35.972854 13.081038-52.324152z m994.158882 569.02515H56.139454V481.000665h1023.591218v477.457885z"
                  fill="#ffffff" p-id="2749"></path>
              <path
                  d="M854.082768 647.783899H285.057618v134.080639h565.75489V647.783899h3.27026zM929.298736 160.515236H209.84165c-13.081038 0-19.621557 9.810778-19.621557 22.891817 0 13.081038 6.540519 19.621557 19.621557 22.891816h716.186826c13.081038-3.270259 19.621557-9.810778 19.621557-22.891816 3.270259-13.081038-6.540519-22.891816-16.351297-22.891817z m-22.891817 117.729342H232.733466c-13.081038 3.270259-19.621557 13.081038-16.351297 26.162075 0 13.081038 13.081038 19.621557 29.432335 19.621557h657.322156c13.081038-3.270259 19.621557-9.810778 19.621557-22.891816s-6.540519-19.621557-16.351298-22.891816z"
                  fill="#ffffff" p-id="2750"></path>
            </svg>
          </div>
          <div style="font-size: 18px;color: #FFFFFF;font-weight: bold;">文章发布</div>
        </div>
      </div>
      <div style="min-height: 100%;overflow:hidden;">
        <div
            style="width: 1920px;background: #E3E4E7;display: flex;height: 100%;overflow: hidden;justify-content: center;margin: 0 auto;">
          <!--  左侧-->
          <div style="overflow: hidden">
            <div
                style="width: 250px;min-height: 860px;background: #FFFFFF;float: left;padding-left: 2px;overflow: hidden">
              <div style="width: 100%;text-align: center;height: 40px;">
                <div style="font-size: 18px;font-weight: bold;padding-top: 15px;">文章发布</div>
              </div>
              <div style="margin-top: 20px;">
              </div>
            </div>
          </div>
          <!--  中间部分 -->
          <div style="width:71%;overflow-x: hidden;overflow-y: hidden;">
            <div class="avatar-uploader" style="float:left;overflow: hidden">
              <div style="width: 1300px;">
                <div style="margin:0px 9px;background: #FFFFFF;overflow:auto;height: 845px;">
                  <div style="width:1257px;float: left;">
                    <div style="height: 40px;margin:10px 20px;border: none;">
                      <el-input style="width:1240px;height: 40px;border: none;font-size: 26px;font-weight: bold;"
                                v-model="title" placeholder="请输入文章标题（不超过50个字）" maxlength="50"></el-input>
                    </div>
                    <!--编辑器-->
                    <div style="margin-left: 20px;">
                      <div style="width:1240px;height:700px;border: 1px solid #999999;">
                        <Editor :tinymce_height="'700'" :value="content" :innerDrawer="innerDrawer = true"
                                ref="ch"></Editor>
                      </div>
                    </div>
                    <!-- 底部按钮 -->
                    <template>
                      <div v-if="typeof $route.query.menuId == 'undefined' " @click="addSaveArticle"
                           style="width: 130px;height: 40px;margin: 20px 5px;float: right">
                        <el-button type="primary" class="el-icon-position">保存</el-button>
                      </div>
                      <div v-else @click="add_draft_submit"
                           style="width: 130px;height: 40px;margin: 20px 5px;float: right">
                        <el-button type="primary" class="el-icon-position">发布文章</el-button>
                      </div>

                    </template>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!--  右侧部分 -->
          <div style="background: #FFFFFF;height: 868px;width: 400px;">
            <el-button style="float:left;background: #2970FF;height: 34px;margin: 20px;" type="info" size="mini"
                       @click="create_thumb_pic()" slot="reference">获取文章图片
            </el-button>
            <el-dialog title="图片封面" :visible.sync="dialogShow" :style="{minHeight: '100px'}" center>
              <div style="width: 100%;">
                <div style="width: 25%;height: 150px;float: left;" v-for="(item,index) in cover_imgs" :key="index"
                     @click="change_border(index)" :class="{'active':index==coverIndex}">
                  <img style="width: 100%;height:100%;overflow: hidden;" :src="item+'-300x150'">
                </div>
              </div>
            </el-dialog>

            <div style="height: 200px;">
              <div style="width: 156px;height: 106px;margin-left: 7px;">
                <el-upload style="margin-left: 12px;width: 156px;height: 106px;" drag action=""
                           :http-request="upload_img2" :show-file-list="false" v-if="cover == '' ">
                  <i class="el-icon-plus"
                     style="width: 20px;height: 20px;font-size: 20px;color: #2970FF;margin-top:40px;text-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);font-weight: 400;"></i>
                  <div style="font-size: 12px;color: #666666">点击上传封面</div>
                </el-upload>
              </div>
              <div style="width: 170px;height: 106px;position: relative;margin-top: -30px;margin-left: 12px"
                   v-if="cover != ''">
                <img :src="cover" style="width: 156px;height: 106px;border-radius: 6px">
                <div
                    style="background:#ff7575;color:#FFF;text-align:center;width:20px;line-height:20px;position: absolute;left: 135 px;bottom: 87px; cursor:pointer;user-select: none;"
                    @click="removeImg2()">
                  <i class="el-icon-close"></i>
                </div>
              </div>
            </div>
            <div style="width: 100%;border-top: 1px solid #BDBFC1;"></div>
            <div style="min-height: 450px">
              <!--   文章来源   -->
              <div style="width: 100%;">
                <div style="margin: 10px;">
                  <div style="height: 17px;font-size: 16px;color: #545454;float: left;margin-left: 10px;">文章来源</div>
                  <div style="float: left;margin-left: 10px;margin-top: 2px;">
                    <template>
                      <el-radio v-model="form" :label="1">原创文章</el-radio>
                      <el-radio v-model="form" :label="2">转载推荐</el-radio>
                    </template>
                  </div>
                </div>
              </div>
              <!--  原文链接 -->
              <div style="width: 100%;clear: both">
                <div style="height: 38px;padding: 15px 20px">
                  <el-input v-model="reprintedaddress" placeholder="请填写原文链接" :disabled="form === 1"></el-input>
                </div>
              </div>
              <el-checkbox style="margin-left: 20px;" v-model="isreprinted">*原文需允许转载或本次转载已获得原文作者授权</el-checkbox>
              <!--  文章话题   -->
              <!--                        <div style="width: 100%;display: flex;margin: 10px 20px">-->
              <!--                            <div style="width: 20%;margin: 10px  0 20px 0;font-size: 16px;" >文章话题</div>-->
              <!--                            <div style="width: 75%;">-->
              <!--                                <el-select style="width: 240px;" v-model="topicId" label="请选择话题">-->
              <!--                                    <el-option v-for="item in top_topic_list" :key="item.id" :label="item.name" :value="item.id"></el-option>-->
              <!--                                </el-select>-->
              <!--                            </div>-->
              <!--                        </div>-->
            </div>
            <div style="width: 100%;border-top: 1px solid #BDBFC1;"></div>
            <!-- 上传附件   -->
            <el-button style="float:left;background: #2970FF;height: 34px;margin: 20px;" type="info" size="mini"
                       @click="set_upload_file_open">上传文章附件
            </el-button>
            <div v-if="files.length" style="color: #999999;font-size: 14px;float: right;margin: 30px;">当前附件:<span
                style="color: #2970FF">{{ files.length }}</span>个
            </div>
            <!--传文件-->
            <el-dialog :show-close=false :visible.sync="upload_file_open" width="398px">
              <el-tabs v-model="file_tag_name" type="border-card">
                <el-tab-pane label="电脑上传" name="pc">
                  <el-upload accept=".pdf, .doc, .docx, .xls, .xlsx, .ppt, .pptx" class="upload_url" style="width: 330px;"
                             action="" drag multiple :http-request="upload_file" :show-file-list="false">
                    <i class="el-icon-upload"></i>
                    <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
                    <span>上传<em style="color: #409EFF">pdf、doc、docx、xls、xlsx、ppt、pptx</em>格式</span>
                  </el-upload>
                </el-tab-pane>
                <el-tab-pane label="手机上传" name="mobile">
                  <div style="padding:10px;color:#a6a6a6;line-height:20px;">
                    <p>使用微信“扫一扫”打开小程序</p>
                    <P>在 百工宝小程序 中向“文件助手”发送文件</P>
                  </div>
                  <div style="text-align:center;">
                    <img :src="upload_qrcode" style="width:150px;height:150px;">
                  </div>
                </el-tab-pane>
              </el-tabs>
              <div style="overflow: hidden; height:55px;line-height:20px;">
                <div style="float:left;color:#acacac;margin-top: 18px">
                  文件数:{{ files.length }}
                </div>
                  <el-button size="small" style="border-radius: 5px; float: right;background-color: #031F88; color: #fff; margin: 10px 16px 10px 20px"
                             @click="upload_file_open = false">确定
                  </el-button>
                <el-button style="border-radius: 5px;float: right;margin-top: 10px" size="small" @click="cancelFiles">取消</el-button>

              </div>
              <div style="overflow:hidden;">
                <div class="video" v-for="(item,index) in files" :key="index"
                     style="width:80px;height:100px;border-radius:3px; float:left; margin:5px; background-size:100%;position:relative;"
                     :title="item.name">
                  <div style="width:100%;height:calc(100% - 20px);background-color: rgba(0,0,0,.3);">
                    <img :src="ossUrl+'img/file.png'" style="width:100%;height:100%;"/>
                  </div>
                  <div
                      style="height:20px;width:70px;margin: 0 5px;line-height: 20px;text-align: center;word-break:keep-all;white-space:nowrap;overflow:hidden;text-overflow:ellipsis;">
                    {{ item.name }}
                  </div>
                  <div
                      style="background:#ff7575;color:#FFF;text-align:center;width:20px;height:20px;line-height:20px;border-radius:10px; cursor:pointer;user-select: none;position:absolute;right:0;top:0;"
                      @click="removeFile(index)">
                    <i class="el-icon-close"></i>
                  </div>
                </div>
              </div>
            </el-dialog>
          </div>
        </div>
      </div>
      <el-dialog title="图片转化进度" :close-on-click-modal="false" :show-close=false :visible.sync="upload_file_loading"
                 width="398px">
        <el-progress :text-inside="true" :stroke-width="26" :percentage="progress"></el-progress>
      </el-dialog>
    </div>
  </template>

  <script>
  import Editor from "@/components/common/Editor.vue"

  // import Details from "./components/Details.vue"

  export default {
    name: 'Aricles',
    components: {
      Editor,
      // Details
    },
    computed: {
      user() {
        return this.$store.state.user
      }
    },
    created: function () {
      this.$EventBus.$emit("is_show", {is_show: 0});
    },
    data() {
      return {
        title: "",
        content_list: [],
        title_list: [],
        title_index: 0,
        abstracts: "",
        content: "",
        activeName: "first",
        current_class_id: 13,
        thumb: "",
        common_type: "4",
        thumbs: [
          "https://baigongbao.oss-cn-beijing.aliyuncs.com/img/thumbnail1.jpg",
          "https://baigongbao.oss-cn-beijing.aliyuncs.com/img/thumbnail2.jpg",
          "https://baigongbao.oss-cn-beijing.aliyuncs.com/img/thumbnail3.jpg",
          "https://baigongbao.oss-cn-beijing.aliyuncs.com/img/thumbnail4.png"
        ],
        channel_id: '',
        topic_like: "",
        imgs: [],
        topic: 0,
        topic_name: "",
        channel_list: [],
        is_thumbs_show: 0,
        new_content: {
          user: {
            headIco: "",
          }
        },
        add_content_id: 0,  //新增文章的ID
        form: 1,

        isreprinted: true,
        //上传
        upload_file_open: false,
        files: [],
        file_tag_name: 'pc',
        upload_qrcode: "https://baigongbao.oss-cn-beijing.aliyuncs.com/img/xcx_qrcode.jpg-80*80",

        kwType: 0,
        type: 0,
        fmList: [],

        //route.push过来的话题ID
        receiveTopicId: 0,

        dialogShow: false,
        //原文
        reprintedaddress: '',
        //封面
        cover_imgs: [],
        coverIndex: 0,
        cover: "",

        //图片进度
        upload_file_loading: false,
        images: [],
        loadedImages: 0,
        totalImages: 0,
        progress: 0,
      }
    },
    mounted: function () {
      var that = this;
      if (typeof this.$route.params.topicId != "undefined") {
        console.log(this.$route.params.topicId);
        this.receiveTopicId = this.$route.params.topicId;
      }
      if (typeof this.$route.query.menuId == 'undefined') {
        //  获取文章详情
        this.get_article_info();
      }

      //监听图片进度
      this.$EventBus.$on("uploadQuantity", (data) => {
        this.$nextTick(() => {
          this.totalImages = data.totalImages;
          this.progress = Math.floor((data.imageCount / this.totalImages) * 100);
          console.log(data.imageCount);
          console.log(this.totalImages);
          // 如果所有图片都加载完成，可以执行其他操作
          if (data.imageCount == this.totalImages) {
            this.utils.sus("所有图片转化完毕");
            this.cover_imgs = data.oss_url;
            let chil = that.$refs.ch;
            chil.set_content(data.content);
            this.dialogShow = true;
            this.upload_file_loading = false;
          }
        });
      });

    },
    methods: {
      // 保存文章
      addSaveArticle() {
        var that = this;
        var params = {};
        params.type = 2;
        params.form = that.form;
        params.title = that.title;
        if (params.title == "") {
          this.utils.err("标题不能为空");
          return;
        }

        let chil = that.$refs.ch;
        let content = chil.get_content();
        content = content.replace(/amp;/g, "");
        params.content = content;
        if (params.content == "") {
          that.utils.err("请填写内容");
          return false;
        }
        params.uuid = that.user.uuid;
        // params.auth = that.common_type;
        params.enclosure = that.files;

        if (that.cover == "") {
          that.utils.err("请上传封面");
          return false;
        }
        params.cover = that.cover;

        params.isComment = 1;

        if (that.isreprinted == true) {
          params.isReprinted = 1;
        } else {
          params.isReprinted = 2;
        }
        params.ReprintedAddress = that.reprintedaddress;

        if (params.form == '2') {
          if (that.ReprintedAddress == "") {
            that.utils.err("请填写转载地址");
            return false;
          }
        }
        params.topicId = that.topicId;

        params.operationType = 1;


        that.articleAddOrSavecontent(params);
      },
      articleAddOrSavecontent(params) {
        var that = this;
        that.newApi.editTemplateContent({content: JSON.stringify(params), id: that.$route.query.id}).then((ret) => {
          if (ret.isSuccess == 1) {
            that.utils.sus("修改成功");
            that.utils.a('/room/index')
          }
        }).catch((err) => {
          console.log(err)
        })
      },
      set_upload_file_open: function () {
        this.upload_file_open = true
      },
      // 取消文件上传
      cancelFiles() {
        this.files = [];
        this.upload_file_open = false;
      },

      //标签页切换
      handleClick() {
        var that = this;
        if (that.activeName == "first") {
          that.set_data(that.title_list);
        } else if (that.activeName == "second") {
          that.get_article_list();
        }
      },

      set_data: function (data, index_x) {
        var that = this;
        var index = index_x;
        if (typeof index == "undefined") {
          index = 0;
        }
        for (let i = 0; i < data.length; i++) {
          data[i].isactive = 0;
        }
        data[index].isactive = 1;
        if (data.length > 0) {
          that.form = data[index].form.toString();
          that.title = data[index].title;
          that.content = data[index].content;
          that.common_type = data[index].auth;
          that.thumb = data[index].cover;
          if (data[index].isreprinted == 1) {
            that.isreprinted = true;
          } else {
            that.isreprinted = false;
          }
          that.reprintedaddress = data[index].reprintedaddress;
          that.topic = data[index].topicid;
          that.topic_name = data[index].topicname;
          that.channel_id = data[index].channelid;
        }
      },

      //获取已发布的文章
      get_article_list: function () {
        var that = this;
        that.newApi.getArticleList({articleType: 2}).then((ret) => {
          if (ret.isSuccess == 1) {
            var data = ret.data;
            for (var i = 0; i < data.length; i++) {
              data[i].checked = 0;
              data[i].isactive = 0;
              if (i == 0) {
                data[i].isactive = 1;
              }
            }
            that.content_list = data;
            that.get_article_info(that.content_list[0].id);
          }
        })
      },

      //点击已发布文章列表
      content_title_background: function (index) {
        for (var i = 0; i < this.content_list.length; i++) {
          this.content_list[i].isactive = 0;
          if (index == i) {
            this.content_list[i].isactive = 1;
          }
        }
        this.get_article_info(this.content_list[index].id);
      },

      //获取文章详情
      get_article_info: function () {
        var that = this;
        this.newApi.getRoomContentInfo({
          id: that.$route.query.id
        }).then((res) => {
          that.title = res.data.content.title;
          that.content = res.data.content.content;
          that.cover = res.data.content.cover;
          that.form = res.data.content.form;
          that.topicId = res.data.content.topicId;
          that.isReprinted = res.data.content.isReprinted;
          that.files = res.data.content.enclosure
        }).catch((err) => {
          console.log(err)
        })
      },

      //鼠标移入标题事件
      content_move_title: function (index) {
        for (var i = 0; i < this.content_list.length; i++) {
          this.content_list[i].checked = 0;
          if (index == i) {
            this.content_list[i].checked = 1;
          }
        }
      },

      //鼠标移出标题表事件
      content_leave_title: function (index) {
        this.content_list[index].checked = 0;
      },

      //获取草稿箱内容
      get_article_draft_list: function (index_x) {
        var that = this;
        var index = index_x;
        that.newApi.roomStandardRelease({}).then((ret) => {
          if (ret.isSuccess == 1) {
            var data = ret.data;
            for (var i = 0; i < data.length; i++) {
              data[i].checked = 0;
              data[i].isactive = 0;
              if (i == 0) {
                data[i].isactive = 1;
              }
              data[i].thumbs = that.default_thumbs;
            }

            that.get_channel_list();    //获取频道列表


            //刷新页面默认右侧内容
            that.set_data(data, index);
            that.title_list = data;
            if (data.length > 0) {
              that.get_channel_list(data[0].channelid);    //推荐频道
            }
          }
        }).catch((err) => {
          console.log(err)
        })
      },

      //获取推荐频道
      get_channel_list: function (channel_id) {
        var that = this;
        that.newApi.getChannelList({}).then((ret) => {
          if (ret.isSuccess == 1) {
            var data = [];
            for (let i = 0; i < ret.data.length; i++) {
              if (ret.data[i].classid == 1) {
                data.push(ret.data[i]);
              }
            }
            that.channel_list = data;
            if (channel_id > 0) {
              for (var i = 0; i < data.length; i++) {
                if (channel_id == data[i].id) {
                  that.channel_id = data[i].id;
                }
              }
            } else {
              that.channel_id = '';
            }

          }
        })
      },

      //删除某个标题列表
      del_title_list: function (index) {
        var that = this;
        this.$confirm('此操作将永久删除该文章, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {

          var id = that.title_list[index].id;
          if (id > 0) {
            this.ajax("del_draftbox_list", {id: id}, function (ret) {
              that.sus(ret.data);
            })
          }
          that.title_list.splice(index, 1);
          this.title_list[0].isactive = 1;
          this.title = this.title_list[0].title;
        }).catch((err) => {
          console.log(err);
        });
      },

      //点击标题列表改变div背景色
      set_title_background: function (index) {
        this.title_index = index;
        this.thumbs = [];
        // this.$confirm('切换前请保存是否继续?', '提示', {
        //     confirmButtonText: '确定',
        //     cancelButtonText: '取消',
        //     type: 'warning'
        // }).then(() => {
        //     this.$message({
        //         type: 'success',
        //         message: '删除成功!'
        //     });
        // }).catch(() => {
        //     this.$message({
        //         type: 'info',
        //         message: '已取消删除'
        //     });
        // });
        //默认清空数据
        // this.set_remove_data();
        var title = this.title_list[index].title;
        if (title == "未命名") {
          this.title = "";
        } else {
          this.title = this.title_list[index].title;
        }
        let chil = this.$refs.ch;
        chil.set_content(this.title_list[index].content);   //不同草稿不同内容

        this.thumb = this.title_list[index].cover;
        if (this.title_list[index].isreprinted == 1) {
          this.isreprinted = true;
        } else {
          this.isreprinted = false;
        }

        this.common_type = this.title_list[index].auth + "";
        this.topic = this.title_list[index].topicid;
        this.topic_name = this.title_list[index].topicname;
        if (this.title_list[index].channelid == 0) {
          this.channel_id = "";
        } else {
          this.channel_id = this.title_list[index].channelid;
        }

        this.form = this.title_list[index].form + "";
        this.reprintedaddress = this.title_list[index].reprintedaddress;
        for (var i = 0; i < this.title_list.length; i++) {
          this.title_list[i].isactive = 0;
          if (index == i) {
            this.title_list[i].isactive = 1;
          }
        }
      },

      //鼠标移入标题列表事件
      move_title: function (index) {
        for (var i = 0; i < this.title_list.length; i++) {
          this.title_list[i].checked = 0;
          if (index == i) {
            this.title_list[i].checked = 1;
          }
        }
      },

      //鼠标移出标题列表事件
      leave_title: function (index) {
        this.title_list[index].checked = 0;
      },

      //上传封面
      upload_img2: function (params) {
        var file = params.file;
        var that = this;
        const isLt5M = file.size / 1024 / 1024 < 20
        if (!isLt5M) {
          that.utils.err('上传文件大小不能超过 20MB!')
          return false
        }
        that.utils.upload(file, function (url) {
          if (!url) {
            return false;
          }
          that.cover = url.url;
        })
      },

      //移除封面
      removeImg2: function () {
        var that = this;
        that.utils.confirm("移除这个封面？", function () {
          that.cover = "";
        })
      },

      //生成文章封面
      create_thumb_pic() {
        var that = this;
        var imgReg = /<img.*?(?:>|\/>)/gi;
        var srcReg = /src=['"]?([^'"]*)['"]?/i;
        let chil = that.$refs.ch;
        var content = chil.get_content();
        content = content.replace(/amp;/g, "");
        var arr = content.match(imgReg);
        console.log(arr);
        if (arr == null) {
          that.cover_imgs = that.thumbs;
          that.dialogShow = true;
        } else {
          var imgs = [];

          for (var i = 0; i < arr.length && i < 10; i++) {
            var src = arr[i].match(srcReg);
            imgs.push(src[1]);
          }
          var contains = 0;
          for (let i = 0; i < imgs.length; i++) {
            if (imgs[i].includes("baigongbao")) {
              contains++;
            }
          }
          if (contains != imgs.length)  {
            that.progress = 0;
            that.upload_file_loading = true;
            //替换需要转换OSS的图片地址
            that.newApi.uploadThumbImg({
              content: chil.get_content()
            })
          }else{
            that.cover_imgs = imgs;
            that.dialogShow = true;
          }
        }
      },

      //获取话题列表
      // getTopTopicList: function () {
      //     var that = this;
      //     that.newApi.getTopTopicList({}).then((ret) => {
      //         if (ret.isSuccess == 1) {
      //             that.top_topic_list = ret.data;
      //         }
      //     }).catch((err) => {
      //         console.log(err);
      //     })
      // },

      //话题搜索及显示
      search_topic() {
        var that = this;
        if (that.topic_like != "") {
          let param = {};
          param.like = that.topic_like;
          that.newApi.getTopTopicList(param).then((ret) => {
            if (ret.isSuccess == 1) {
              that.top_topic_list = ret.data;
            }
          }).catch((err) => {
            console.log(err);
          })
        } else {
          that.get_top_topic_list();
        }
      },

      handleCommand(obj) {
        this.topic_name = obj.name;
        this.topic = obj.id;
      },

      //监听选中封面样式
      change_border(index) {
        this.coverIndex = index;
        this.cover = this.cover_imgs[index];
      },

      getCaption: function (obj) {
        var index = obj.lastIndexOf("=");
        obj = obj.substring(index + 1, obj.length);
        obj = obj.slice(0);
        obj = obj.replace(/'/g, '');
        obj = obj.replace(/>/g, '');
        return obj;
      },

      //监听标题输入
      change_title: function () {
        var index = this.title_index;
        this.title_list[index].title = this.title;
      },

      //监听推荐频道change事件
      change_channel: function () {
        var index = this.title_index;
        this.title_list[index].channel_id = this.channel_id;
      },

      //监听文章是否公开
      // change_common_type: function () {
      //     var index = this.title_index;
      //     this.title_list[index].common_type = this.common_type;
      // },

      //上传附件
      upload_file: function (params) {
        var that = this;
        var file = params.file;
        that.openLoading('正在上传请稍后')
        that.utils.upload(file, function (url) {
          if (!url) {
            return false;
          }
          var obj = {
            name: url.name,
            url: url.url,
            hash: url.hash
          };
          that.files.push(obj);
        })
      },

      //移除附件
      removeFile: function (index) {
        var that = this;
        that.fmList.splice(index, 1);
        that.files.splice(index, 1);
      },

      //保存为草稿
      save_draft: function () {
        var that = this;
        //处理附件统一上传
        if (that.fmList.length > 0 && that.files.length > 0) {
          that.files = [];
          let filesPromise = [];
          for (let i = 0; i < that.fmList.length; i++) {
            console.log(1);
            filesPromise.push(new Promise(function (resolve) {
              that.utils.upload(that.fmList[i], function (url) {
                if (!url) {
                  return false;
                }
                let obj = {};
                obj.img_url = that.ossUrl + 'img/file.png';
                obj.name = that.fmList[i].name.substring(0, that.fmList[i].name.lastIndexOf("."));
                obj.size = that.fmList[i].size;
                obj.type = that.fmList[i].type;
                obj.url = url;
                resolve(obj);
              })
            }))
          }
          Promise.all(filesPromise).then(res => {
            that.files = res;
            that.save_draft_submit();
          })
        } else {
          that.save_draft_submit();
        }
      },

      save_draft_submit() {
        var that = this;
        var index = that.title_index;
        var params = {};
        params.id = that.title_list[index].id;
        params.draftType = 1;  //文章草稿
        params.form = that.form;
        params.title = that.title;
        if (params.title == "") {
          this.utils.err("标题不能为空");
          return;
        }

        let chil = that.$refs.ch;
        let content = chil.get_content();
        content = content.replace(/amp;/g, "");
        params.content = content;
        params.uuid = that.user.uuid;
        // params.auth = that.common_type;
        params.enclosure = "[]";
        params.cover = that.thumb;
        params.isComment = 1;
        if (that.isreprinted == true) {
          params.isReprinted = 1;
        } else {
          params.isReprinted = 2;
        }
        params.ReprintedAddress = that.reprintedaddress;
        if (params.form == '2') {
          if (that.ReprintedAddress == "") {
            that.utils.err("请填写转载地址");
            return false;
          }
        }

        params.topicId = that.topic;
        // params.channelId = that.channel_id;
        //id =0为新增草稿 >0 为已存的草稿
        if (params.id == 0) {
          params.operationType = 1;
        } else {
          params.operationType = 2;
        }
        that.newApi.articleDraftAddOrSave(params).then((ret) => {
          if (ret.isSuccess == 1) {
            that.utils.sus(ret.data);
            that.get_article_draft_list(index);
          }

        })
      },

      add_draft: function () {
        this.upload_file_open = false;
        var that = this;
        //处理附件统一上传
        if (that.fmList.length > 0 && that.files.length > 0) {
          that.files = [];
          let filesPromise = [];
          for (let i = 0; i < that.fmList.length; i++) {
            console.log(1);
            filesPromise.push(new Promise(function (resolve) {
              that.utils.upload(that.fmList[i], function (url) {
                if (!url) {
                  return false;
                }
                let obj = {};
                obj.img_url = that.ossUrl + 'img/file.png';
                obj.name = that.fmList[i].name.substring(0, that.fmList[i].name.lastIndexOf("."));
                obj.size = that.fmList[i].size;
                obj.type = that.utils.get_suffix(that.fmList[i].name);
                obj.url = url;
                resolve(obj);
              })
            }))
          }
          Promise.all(filesPromise).then(res => {
            that.files = res;
            that.add_draft_submit();
          })
        } else {
          that.add_draft_submit();
        }
      },

      //发布按钮
      add_draft_submit: function () {
        var that = this;
        var index = that.title_index;
        var params = {};
        params.type = 2;
        params.form = that.form;
        params.title = that.title;
        if (params.title == "") {
          this.utils.err("标题不能为空");
          return;
        }

        let chil = that.$refs.ch;
        let content = chil.get_content();
        content = content.replace(/amp;/g, "");
        params.content = content;
        if (params.content == "") {
          that.utils.err("请填写内容");
          return false;
        }
        params.uuid = that.user.uuid;
        // params.auth = that.common_type;
        params.enclosure = that.files;

        if (that.cover == "") {
          that.utils.err("请上传封面");
          return false;
        }
        params.cover = that.cover;

        params.isComment = 1;

        if (that.isreprinted == true) {
          params.isReprinted = 1;
        } else {
          params.isReprinted = 2;
        }
        params.ReprintedAddress = that.reprintedaddress;

        if (params.form == '2') {
          if (that.ReprintedAddress == "") {
            that.utils.err("请填写转载地址");
            return false;
          }
        }
        params.topicId = that.topicId;

        // params.channelId = that.channel_id;
        params.operationType = 1;

        var id = that.$route.query.id;
        //>0草稿发布 <=0 新加文章直接发布
        if (id > 0) {
          params.id = id;
          that.articleAddOrSave(params, index);
        } else {
          that.articleAddOrSave(params, index);
        }
      },
      articleAddOrSave(params, index) {
        var that = this;
        var menuId = '0';
        if (that.$route.query.menuId !== '') {
          menuId = that.$route.query.menuId
        }
        that.openLoading('发布中，请稍后');
        that.newApi.roomStandardRelease({
          content: JSON.stringify(params),
          roomId: that.$route.query.id,
          menuId: menuId
        }).then((ret) => {
          if (ret.isSuccess == 1) {
            that.utils.sus(ret.data);
            that.title_list.splice(index, 1);
            if (that.title_list.length > 0) {
              that.set_title_background(index);
            }
            //发布以后取消默认话题
            that.topicId = 0;
            that.content = "";
            that.title = "";
            that.cover = "";
            that.files = "";
            that.utils.a('/room/info/' + that.$route.query.id)
          }
        }).catch((err) => {
          console.log(err)
        })
      }
    }
  }
  </script>

  <style scoped>
  /deep/ .el-tabs__header {
    margin: 0;
  }

  .title {
    font-size: 14px;
    line-height: 35px;
    padding-left: 3px;
    color: #000000;
    display: inline-block;
    white-space: nowrap;
    width: 90%;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .title.active {
    color: red;
  }

  .title_background {
    width: 100%;
    height: 60px;
    background: #FFFFFF;
    cursor: pointer;
    padding-right: 115px;

  }

  .title_background.active {
    background: #F2F2F5;
  }

  .demo_line_01 {
    width: 60px; /*这指的是文字的宽度*/
    padding: 0 20px 0;
    margin: 20px auto;
    line-height: 1px;
    border-left: 100px solid #ddd;
    border-right: 100px solid #ddd;
    text-align: center;
  }

  .avatar {
    width: 280px;
    height: 180px;
    display: block;
    float: left;
    margin-top: 10px;
    border-radius: 0px;
    margin-left: 25px;
  }

  .avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    cursor: pointer;
    /*position: relative;*/
    overflow: hidden;
    float: left;
  }

  .avatar-uploader .el-upload:hover {
    border-color: #409EFF;
  }

  .cover_border {
    width: 150px;
    height: 100px;
    float: left;
    margin: 3px;
  }

  .cover_border.active {
    border: 1px solid #c1c1c1;
    height: 98px;
    width: 148px;
    box-shadow: 1px 1px 3px 1px #409eff;
  }

  .el-radio {
    margin-right: 5px;
    font-size: 14px;
  }

  /deep/ .el-upload-dragger {
    width: 154px !important;
    height: 104px !important;
    margin-top: 5px;
  }

  /deep/ .el-radio__label {
    padding-left: 1px;
  }

  /deep/ .el-dialog__body {
    padding: 20px 20px;
  }

  /deep/ .el-dialog__body {
    display: flex;
    flex-direction: column;
  }

  .active {
    box-shadow: 0 0 5px 10px #409eff;
  }

  /deep/ .upload_url .el-upload-dragger {
    width: 330px !important;
    height: 164px !important;
  }
  </style>
